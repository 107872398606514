// Script5.js
export default function Script5() {
  return (
      <div className="card-outer">
        <div className="text-header">
          <div>trailing success rate</div>
        </div>
          <div className="nested-card">
            <div className="trailing-success-chart">lines n sale stuff</div>
          </div>
      </div>
  );
}

