import React, { useEffect } from 'react';
import zingchart from 'zingchart/es6';
import monthGoalData from '../data/monthGoal.js';
import { currentMonthName } from '../data/monthGoal';
import data from '../data/yearGoalData';

export default function Script2() {
  // useEffect(() => {
  //   zingchart.render({
  //     id: 'myChart',
  //     data: {
  //       type: 'gauge',
  //       backgroundColor: 'none',
  //       plot: {
  //         size: '100%',
  //         valueBox: {
  //           visible: false,
  //           placement: 'center', // place the value in the center
  //           text: '%v', //default
  //           fontSize: 20,
  //           rules: [{
  //             rule: '%v >= ' + monthGoalData.benchmark,
  //             text: '%v<br>Good'
  //           }, {
  //             rule: '%v < ' + monthGoalData.benchmark + ' && %v > ' + monthGoalData.current,
  //             text: '%v<br>Average'
  //           }, {
  //             rule: '%v <  ' + monthGoalData.current,
  //             text: '%v<br>Bad'
  //           }]
  //         }
  //       },
  //       scaleR: {
  //         aperture: 180,
  //         minValue: 0,
  //         maxValue: monthGoalData.benchmark * 2,
  //         step: 1000000,
  //         center: {
  //           visible: false
  //         },
  //         tick: {
  //           visible: false
  //         },
  //         item: {
  //           offsetR: 0,
  //           visible: false,
  //           rules: [{
  //             rule: '%i == 9',
  //             offsetX: 15
  //           }]
  //         },
  //         labels: ['', '', '', '', '', '', '', '', '', ''],
  //         ring: {
  //           size: 25,
  //           rules: [{
  //             rule: '%v <= ' + monthGoalData.current,
  //             backgroundColor: '#E53935'
  //           }, {
  //             rule: '%v > ' + monthGoalData.current + ' && %v < ' + monthGoalData.benchmark,
  //             backgroundColor: '#FFA726'
  //           }, {
  //             rule: '%v >= ' + monthGoalData.benchmark,
  //             backgroundColor: '#7CB342'
  //           }]
  //         }
  //       },
  //       series: [{
  //         values: [monthGoalData.current], // starting value
  //         backgroundColor: 'white',
  //         indicator: [1, 1, 10, 10, 1],
  //         animation: {
  //           effect: 2,
  //           method: 1,
  //           sequence: 4,
  //           speed: 900
  //         },
  //       }]
  //     },
  //     height: '175',
  //     width: '100%',
  //   });
  // }, []);
  function formatCurrencyInMillions(number) {
    return `$${(number / 1000000).toFixed(1)}M`;
  }
  return (
      <div className="card-outer">
        <div className="text-header">
          <div className="head-left">{currentMonthName} goal</div>
          <div className="head-right">{formatCurrencyInMillions(monthGoalData.current)}</div>
        </div>
        <div className="grid-container">
          <div className="nested-card">current<br />{formatCurrencyInMillions(monthGoalData.current)}</div>
          <div className="nested-card">benchmark<br />{formatCurrencyInMillions(monthGoalData.benchmark)}</div>
          <div style={{gridColumn: '2', gridRow: '1 / span 2'}} className="nested-card">
            Summary
            <div id="myChart"></div>
          </div>
        </div>
      </div>
  );
}
