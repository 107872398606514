// Script1.js
import React from 'react';
import {SemiCircleProgress} from 'react-semicircle-progressbar';
import data from '../data/yearGoalData.js';

export default function Script1() {
  const percentage = Math.round((data.current / data.goal) * 100);
  const benchmarkPercentage = Math.round((data.benchmark / data.goal) * 100);
  return (
      <div className="card-outer">
        <div className="text-header">
          <div className="head-left">{data.year} Goal</div>
          <div className="head-right">${data.goal}M</div>
        </div>
        <div className="grid-container">
          <div className="nested-card">current<br/>${data.current}M</div>
          <div className="nested-card">benchmark<br/>${data.benchmark}M</div>
          <div style={{gridColumn: '2', gridRow: '1 / span 2'}} className="nested-card">Summary
            <div className="year-goal-chart">

              <SemiCircleProgress
                  percentage={percentage}
                  className="hide-percentage"
                  size={{
                    width: 175,
                    height: 125,
                  }}
                  strokeLinecap="round"
                  strokeWidth={20}
                  strokeColor="#f00"
                  hasBackground={true}
                  bgStrokeColor="black"
              />
            </div>
          </div>
        </div>
      </div>
  );
}
