// Script6.js
import salesmen from '../data/salesmanData.js';
export default function Script6() {
  // Sort the salesmen array in descending order of sales
  const sortedSalesmen = salesmen.sort((a, b) => b.sales - a.sales);

  // Get the top 10 salesmen
  const topSalesmen = sortedSalesmen.slice(0, 10);

  // Create a formatter for currency
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="card-outer3">
      <div className="text-header-center">
        <div>trailing success rate</div>
      </div>
      <div className="grid-container3">
        {topSalesmen.map((salesman, i) => (
          <div key={i} className={`salesman-card ${i === 0 ? 'first-place' : ''}`}>
            <img src="https://s3.us-east-2.amazonaws.com/cdn.pwiworks.com/images/JeffMiller-1.jpg" alt="salesman" className="salesman-image" />
            <div className="salesman-info">
              <div className="salesman-name">{salesman.name}</div>
              {i === 0 && (
                <>
                  <div className="numbers">{formatter.format(salesman.sales)}</div>
                  <div>Projects: <div className="numbers">{salesman.projects}</div></div>
                  <div>Travelers: <div className="numbers">{salesman.travelers}</div></div>
                  <div>Avg Cpt: <div className="numbers">{salesman.avgCpt}</div></div>
                      </>
              )}
              {i !== 0 && <div>${Math.round(salesman.sales / 1000)}K</div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
