import React, { useRef, useLayoutEffect, memo } from 'react';
import { gaugeChart } from 'gauge-chart';
import { weeklyGoalData } from '../data/weeklyGoal'; // Import weeklyGoalData

const Script3 = (props) => {
  console.log('Script3 props:', props); // Add this line
  console.log('Script3 is rendering'); // Add this line
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    console.log('useLayoutEffect is running'); // Add this line
    if (chartRef.current) {
      // Properties of the gauge
      let gaugeOptions = {
        hasNeedle: true,
        needleColor: 'gray',
        needleUpdateSpeed: 1000,
        arcColors: ['rgb(44, 151, 222)', 'lightgray'],
        arcDelimiters: [30],
        rangeLabel: ['0', '100'],
        centralLabel: '50',
      };

      // Drawing and updating the chart
      gaugeChart(chartRef.current, 100, gaugeOptions).updateNeedle(50); // Corrected usage of gaugeChart
    }
  }, []); // Added empty array as second argument to useEffect

  return (
      <div className="card-outer">
        <div className="text-header">
          <div className="head-left">WEEKLY Goal</div>
          <div className="head-right">${(weeklyGoalData.goal / 1000000).toFixed(1)}M</div>
        </div>
        <div className="grid-container">
          <div className="nested-card">current<br/>${(weeklyGoalData.current / 1000000).toFixed(1)}M</div>
          <div className="nested-card">benchmark<br/>${(weeklyGoalData.benchmark / 1000000).toFixed(1)}M</div>
          <div style={{gridColumn: '2', gridRow: '1 / span 2'}} className="nested-card">summary<br/>.
            <div ref={chartRef}></div>
          </div>
        </div>
      </div>
  );
}

export default memo(Script3);
