// monthGoal.js
// Get the current month number
const currentMonthNumber = new Date().getMonth();

// Map the month number to the month name
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const currentMonthName = monthNames[currentMonthNumber];
const monthGoalData = {
  current: 7500000,
  benchmark: 8000000,
  goal: 8000000
};

export default monthGoalData;
