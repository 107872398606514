import './App.css';
import Script1 from './components/script1';
import Script2 from './components/script2';
import Script3 from './components/script3';
import Script4 from './components/script4';
import Script5 from './components/script5';
import Script6 from './components/script6';

function App() {
  return (
      <div className="dashboard">
        <div className="left">
          <Script1 />
          <Script2 />
          <Script3 />
        </div>
        <div className="center">
          <Script4 />
        </div>
        <div className="middle">
          <Script5 />
        </div>
        <div className="right">
          <Script6 />
        </div>
      </div>
  );
}

export default App;
