export default [
  { name: 'Jason Miller', sales: 1326052, projects: 10, travelers: 5, avgCpt: 100 },
  { name: 'Salesman 2', sales: 4000, projects: 8, travelers: 4, avgCpt: 90 },
  { name: 'Salesman 3', sales: 3500, projects: 7, travelers: 3, avgCpt: 85 },
  { name: 'Salesman 4', sales: 3000, projects: 6, travelers: 3, avgCpt: 80 },
  { name: 'Salesman 5', sales: 2500, projects: 5, travelers: 2, avgCpt: 75 },
  { name: 'Salesman 6', sales: 2000, projects: 4, travelers: 2, avgCpt: 70 },
  { name: 'Salesman 7', sales: 1500, projects: 3, travelers: 1, avgCpt: 65 },
  { name: 'Salesman 8', sales: 1000, projects: 2, travelers: 1, avgCpt: 60 },
  { name: 'Salesman 9', sales: 500, projects: 1, travelers: 1, avgCpt: 55 },
  { name: 'Matt Hall', sales: 1526052, projects: 1, travelers: 1, avgCpt: 50 },
];
