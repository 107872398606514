// Script4.js
export default function Script4() {
  return (
      <div className="card-outer2">
        <div className="text-header">
          <div>national goals</div>
          <div>regional goals</div>
        </div>
        <div className="grid-container2">
          <div className="nested-card regional-national">nat sale stuff</div>
          <div className="nested-card regional-national">reg sales stuff</div>
          <div className="nested-card sales-progression">sales progression by month</div>
        </div>
      </div>
  );
}

